import React, { Component } from "react";
import PropTypes from "prop-types";
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

import styles from "./share.module.css";

export default class Share extends Component {
  render() {
    const { socialConfig, tags } = this.props;
    return (
      <div className={styles.shareParent}>
      Share: 
        <FacebookShareButton
          url={socialConfig.config.url}
          className={styles.shareButton}
        >
          <FaFacebookF />
        </FacebookShareButton>
        <TwitterShareButton
          url={socialConfig.config.url}
          className={styles.shareButton}
          title={socialConfig.config.title}
          via={socialConfig.twitterHandle.split("@").join("")}
          hashtags={tags}
        >
          <FaTwitter />
        </TwitterShareButton>
        <LinkedinShareButton
          url={socialConfig.config.url}
          className={styles.shareButton}
          title={socialConfig.config.title}
        >
          <FaLinkedinIn />
        </LinkedinShareButton>
      </div>
    );
  }
}

Share.propTypes = {
  socialConfig: PropTypes.shape({
    twitterHandle: PropTypes.string.isRequired,
    config: PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    })
  }).isRequired,
  tags: PropTypes.arrayOf(PropTypes.string)
};
Share.defaultProps = {
  tags: []
};
