import React, { Component } from "react";
import { graphql, Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import nextArrow from "../assets/next-arrow.svg";
import Img from "gatsby-image";
import Layout from "./layout";
import PageHeader from "./pageHeader";
import styled from "styled-components";
import Share from "./share";
import Started from "./Layout/started";
import styles from "./postLayout.module.less";
import SEO from "../components/SEO";
import ScrollAnimation from "react-animate-on-scroll";

const ImageDiv = styled.div`
  margin-top: -160px;
  width: 100%;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 991px) {
    margin-top: -80px;
  }
  @media screen and (max-width: 768px) {
    margin-top: -100px;
  }
`;

const StyledTitle = styled.h1`
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.03px;
  color: #242d41;
  margin-bottom: 25px;
  margin-top: 70px;
  @media screen and (max-width: 991px) {
    margin-top: 45px;
  }
  @media screen and (max-width: 768px) {
    margin-top: 30px;
    font-size: 20px;
  }
`;

const StyledText = styled.div`
  font-size: 16px;
  letter-spacing: 0.03px;
  line-height: 1.63;
  color: #242d41;
  p {
    margin-bottom: 1.5rem;
  }
  h1 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 4px;
  }
  h2 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 4px;
  }
  h3 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 4px;
  }
  a {
    color: #1E5BB5;
  }
`;

const LinkDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default class postLayout extends Component {
  render() {
    const {
      data: { markdownRemark },
      path,
      pageContext: { next, prev }
    } = this.props;
    const {
      site: {
        siteMetadata: { url, twitterHandle }
      },
      blogDefault
    } = this.props.data;
    const featuredImgFluid = markdownRemark.frontmatter.featuredImage
      ? markdownRemark.frontmatter.featuredImage.childImageSharp.fluid
      : blogDefault.childImageSharp.fluid;
    return (
      <Layout>
        <SEO
          title={markdownRemark.frontmatter.title}
          image={featuredImgFluid.src}
          description={`Read more about ${markdownRemark.frontmatter.title}`}
        />
        <PageHeader mediumHeader />
        <Container>
          <Row className="justify-content-md-center">
            <Col lg={10} md={11}>
            <ScrollAnimation animateOnce animateIn="fadeIn" offset={0}>
              <ImageDiv>
                <Img fluid={featuredImgFluid} className={styles.topBlogImg} />
              </ImageDiv>
              </ScrollAnimation>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col lg={9} md={10}>
              <StyledTitle>{markdownRemark.frontmatter.title}</StyledTitle>
              <StyledText>
                <div
                  dangerouslySetInnerHTML={{ __html: markdownRemark.html }}
                />
              </StyledText>
            </Col>
          </Row>
          <Row className={`justify-content-md-center ${styles.shareRow}`}>
            <Col md={9} className={styles.shareCol}>
            <ScrollAnimation animateOnce animateIn="fadeIn" offset={0}>
              <Share
                socialConfig={{
                  twitterHandle,
                  config: {
                    url: `${url}${path}`,
                    title: markdownRemark.frontmatter.title
                  }
                }}
              />
              </ScrollAnimation>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col md={9}>

            <ScrollAnimation animateOnce animateIn="fadeInUp" offset={0}>
              <LinkDiv>
                <Link
                  to={next ? next.frontmatter.slug : "/news"}
                  className={styles.nextPrevLink}
                >
                  <img src={nextArrow} alt="prevArrow" />{" "}
                  {next ? "Next" : "Back to News"}
                </Link>
                <Link
                  to={prev ? prev.frontmatter.slug : "/news"}
                  className={styles.nextPrevLink}
                >
                  {prev ? "Previous" : "Back to News"}{" "}
                  <img src={nextArrow} alt="prevArrow" />{" "}
                </Link>
              </LinkDiv>

              </ScrollAnimation>
            </Col>
          </Row>
          <Started />
        </Container>
      </Layout>
    );
  }
}

export const query = graphql`
  query PostQuery($slug: String!) {
    site {
      siteMetadata {
        twitterHandle
        url
      }
    }
    blogDefault: file(relativePath: { eq: "Blog/blog_default_fullhd.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1024) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date
        slug
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
